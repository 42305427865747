<template>
  <div class="d-flex mr-5 mb-1">
    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          data-cy="addEntry"
          fab
          dark
          small
          class="buttons--add margin--1"
          v-on="on"
          @click="$emit('add-service')"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('service:addService') }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['service'] },
    data() {
        return {
            statusFilter: false,
            iconFilter: false,
            direction: 'top',
            addDeviceIcon: 'mdi-plus',
            transition: 'slide-y-reverse-transition',
            statusFilters: [
                new this.Filter('#6b926f', 'opened'),
                new this.Filter('#beb13a', 'started'),
                new this.Filter('#b14040', 'completed'),
                new this.Filter('#555555', 'closed'),
                new this.Filter('#dfafde', 'assigned'),
                new this.Filter('#7a7a7a', 'cancelled'),
                new this.Filter('#484086', 'approved'),
            ],
            filters: [],
        }
    },
    computed: {
        ...mapGetters([
            'getServiceStatuses',
            'getProfileDetails',
            'getDevicesRegistry',
        ]),
    },
    async beforeMount() {
        await this.fetchDevicesRegistry()
        this.addDeviceIcon = this.getDevicesRegistry?.icon
    },

    methods: {
        ...mapActions(['fetchServiceTable', 'fetchDevicesRegistry']),
        ...mapMutations(['setServiceWhere']),

        Filter(color, value) {
            this.color = color
            this.value = value
        },
        setFilter(filter) {
            if (!filter)
                this.setServiceWhere({
                    isDeleted: false,
                })
            else
                this.setServiceWhere({
                    isDeleted: false,
                    'status.name': filter,
                })
            this.fetchServiceTable()
        },
        setIconFilter(filter) {
            this.setServiceWhere({
                isDeleted: false,
                ...filter,
            })
            this.fetchServiceTable()
        },
    },
}
</script>
<style lang="sass" scoped>
.font--circle
    font-size: 13px
</style>
