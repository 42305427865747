var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index},[_c('td',[_c('div',{staticClass:"orderDate service-table"},[_c('DoubleLine',{attrs:{"firtst-line":item && item.createdAt
                            ? _vm.$moment(item.createdAt).format('DD/MM/YYYY')
                            : '',"second-line":item && item.createdAt
                            ? _vm.$moment(item.createdAt).format('HH:mm')
                            : ''}})],1)]),_c('td',{staticClass:"truncate"},[_c('div',{staticClass:"orderingParty"},[_c('span',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.$get( item, 'createdByHelpDeskUsers.name', 'Zlecono poza aplikacją' ))+" "+_vm._s(_vm.$get(item, 'createdByHelpDeskUsers.lastName', ''))+" ")]),(
                        item.createdByHelpDeskUsers &&
                        item.createdByHelpDeskUsers.phone
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.$get(item, 'createdByHelpDeskUsers.phone', '--'))+" ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.$get(item, 'createdByHelpDeskUsers.login', '')))])])]),_c('td',{staticClass:"truncate"},[_c('div',{staticClass:"deviceAndSn"},[_c('div',[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$get(item.device, 'model.manufacturer', ''))+" "+_vm._s(_vm.$get(item.device, 'model.model', '-- --'))+" "),_c('br')]),_c('span',{staticClass:"se"},[_vm._v(" "+_vm._s(_vm.$get(item.device, 'sn', '')))])])])]),_c('td',{staticClass:"truncate"},[_c('v-tooltip',{attrs:{"left":"","min-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$get(item, 'clientDescription', ''))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$get(item, 'clientDescription', '')))])])],1),_c('td',{staticClass:"truncate"},[_c('div',{staticClass:"interventionDate"},[_vm._v(" "+_vm._s(item && item.plannedServiceDate ? _vm.$moment(item.plannedServiceDate).format( 'DD/MM/YYYY' ) : '-- --')+" ")])]),_c('td',{staticClass:"truncate"},[_c('div',{staticClass:"technician"},[_vm._v(" "+_vm._s(((_vm.$get(item, 'technician.name', '--')) + " " + (_vm.$get( item, 'technician.lastname', '--' ))))+" ")])]),_c('td',{staticClass:"truncate"},[_c('div',{staticClass:"checkServiceHappy",on:{"click":function($event){return _vm.onClickServiceHappyDialog(item)}}},[(_vm.checkServiceHappy(item) === 'HAPPY')?_c('div',[_c('v-icon',{attrs:{"size":"20","color":"green"}},[_vm._v("mdi-emoticon-happy-outline")])],1):_vm._e(),(_vm.checkServiceHappy(item) === 'SAD')?_c('div',{on:{"click":function($event){return _vm.onClickServiceHappyDialog(item)}}},[_c('v-icon',{attrs:{"size":"20","color":"red"}},[_vm._v("mdi-emoticon-sad-outline")])],1):_vm._e(),(_vm.checkServiceHappy(item) === '')?_c('div',{on:{"click":function($event){return _vm.onClickServiceHappyDialog(item)}}}):_vm._e()])]),_c('td',[(_vm.hasFile(item))?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-paperclip")]):_vm._e()],1),_c('td',{staticClass:"truncate"},[_c('v-chip',{style:(("color: " + (_vm.$get(
                    item,
                    'status.color.font',
                    '#FFFFFF'
                )))),attrs:{"color":_vm.$get(item, 'status.color.background', '#555555'),"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(_vm.$get(item, 'status.name', _vm.$t('users:empty')))+" ")])],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }