<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <!-- ZAKOMENTOWANE -->
            <!-- :class="`${isStatusClosed(item) ? 'disabled' : ''}`" -->
            <!-- === === ORDER DATE === === -->
            <td>
                <div class="orderDate service-table">
                    <!-- TODO DATE FORMATER -->
                    <DoubleLine
                        :firtst-line="
                            item && item.createdAt
                                ? $moment(item.createdAt).format('DD/MM/YYYY')
                                : ''
                        "
                        :second-line="
                            item && item.createdAt
                                ? $moment(item.createdAt).format('HH:mm')
                                : ''
                        "
                    />
                </div>
            </td>
            <!-- === === ORDERING PARTY === === -->
            <td class="truncate">
                <div class="orderingParty">
                    <!-- <span class="mt-1">{{ `${$get(item,'createdByHelpDesk.name','')} ${$get(item,'createdByHelpDesk.lastname','')}` }}</span>
          <span>{{ $get(item,'createdByHelpDesk.phone','') }}</span>
          <span>{{ $get(item,'createdByHelpDesk.mail','') }}</span>  -->
                    <span class="mt-1">
                        {{
                            $get(
                                item,
                                'createdByHelpDeskUsers.name',
                                'Zlecono poza aplikacją'
                            )
                        }}
                        {{ $get(item, 'createdByHelpDeskUsers.lastName', '') }}
                    </span>
                    <span
                        v-if="
                            item.createdByHelpDeskUsers &&
                            item.createdByHelpDeskUsers.phone
                        "
                    >
                        {{ $get(item, 'createdByHelpDeskUsers.phone', '--') }}
                    </span>
                    <span>
                        {{
                            $get(item, 'createdByHelpDeskUsers.login', '')
                        }}</span
                    >
                </div>
            </td>
            <!-- === === DEVICE AND SERIAL NUMBER === === -->
            <td class="truncate">
                <div class="deviceAndSn">
                    <div>
                        <span class="name">
                            {{ $get(item.device, 'model.manufacturer', '') }}
                            {{ $get(item.device, 'model.model', '-- --') }}
                            <br />
                        </span>
                        <span class="se">
                            {{ $get(item.device, 'sn', '') }}</span
                        >
                    </div>
                </div>
            </td>
            <!-- === === CLIENT DESC === === -->
            <td class="truncate">
                <v-tooltip left min-width="500px">
                    <template #activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            {{ $get(item, 'clientDescription', '') }}
                        </span>
                    </template>
                    <span> {{ $get(item, 'clientDescription', '') }}</span>
                </v-tooltip>
            </td>
            <!-- === === DATE OF THE NEXT INSPECTION === === -->
            <td class="truncate">
                <!-- TODO DATE FORMATER -->
                <!-- {{
          $moment(item.dateOfTheNextInspection).format(
            `DD-MM-YYYY`
          ) || $t('global:empty')
        }} -->
                <div class="interventionDate">
                    {{
                        item && item.plannedServiceDate
                            ? $moment(item.plannedServiceDate).format(
                                  'DD/MM/YYYY'
                              )
                            : '-- --'
                    }}
                </div>
            </td>
            <!-- === === TECHNICIAN === === -->
            <td class="truncate">
                <!-- {{
          $moment(item.insuranceUpTo).format(`DD-MM-YYYY`) ||
            $t('global:empty')
        }} -->
                <div class="technician">
                    {{
                        `${$get(item, 'technician.name', '--')} ${$get(
                            item,
                            'technician.lastname',
                            '--'
                        )}`
                    }}
                </div>
            </td>
            <!-- === === COMMUNICATION === === -->
            <!-- <td>
        <div class="communication">
          <v-icon
            class="cursor-pointer"
            color="#bbb"
            size="20"
          >
            mdi-paperclip
          </v-icon>
          <v-icon
            class="cursor-pointer"
            color="#bbb"
            size="20"
          >
            mdi-calendar-refresh
          </v-icon>
          <v-icon
            class="cursor-pointer"
            color="#bbb"
            size="20"
          >
            mdi-email-outline
          </v-icon>
        </div>
      </td> -->

            <!--- HAPPY/UNHAPPY -->
            <td class="truncate">
                <div
                    class="checkServiceHappy"
                    @click="onClickServiceHappyDialog(item)"
                >
                    <div v-if="checkServiceHappy(item) === 'HAPPY'">
                        <v-icon size="20" color="green"
                            >mdi-emoticon-happy-outline</v-icon
                        >
                    </div>
                    <div
                        v-if="checkServiceHappy(item) === 'SAD'"
                        @click="onClickServiceHappyDialog(item)"
                    >
                        <v-icon size="20" color="red"
                            >mdi-emoticon-sad-outline</v-icon
                        >
                    </div>
                    <div
                        v-if="checkServiceHappy(item) === ''"
                        @click="onClickServiceHappyDialog(item)"
                    ></div>
                </div>
            </td>
            <td>
                <v-icon small v-if="hasFile(item)"
                    >mdi-paperclip</v-icon
                >
            </td>
            <!-- === === STATUS === === -->
            <td class="truncate">
                <v-chip
                    :color="$get(item, 'status.color.background', '#555555')"
                    :style="`color: ${$get(
                        item,
                        'status.color.font',
                        '#FFFFFF'
                    )}`"
                    dark
                    label
                    small
                >
                    {{ $get(item, 'status.name', $t('users:empty')) }}
                </v-chip>
            </td>
        </tr>
    </tbody>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    props: ['items'],
    data: () => ({
        openConfirm: false,
        removeItemID: '',
        showServiceHappyDialogData: {},
    }),
    computed: {
        ...mapGetters(['getProfileDetails']),
    },

    methods: {
        ...mapActions(['deleteCar']),
        hasFile(item) {
          const folders = item?.folders;
            if (folders) {
                const findFolderFromClient = folders.filter(
                    e => e.description === 'Od klienta'
                )

                if (
                    findFolderFromClient.length > 0 &&
                    findFolderFromClient[0].files.length > 0
                ) {
                    return true
                }
            }

            return false
        },
        openConfirmModal(id) {
            this.removeItemID = id
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        async deleteItem() {
            let result = await this.deleteCar({ id: this.removeItemID })
            if (result) this.openConfirm = false
        },
        checkServiceHappy(service) {
            if (service?.realization?.signature?.confirmService === true) {
                return 'HAPPY'
            }
            if (service?.realization?.signature?.confirmService === false) {
                return 'SAD'
            }

            return ''
        },
        onClickServiceHappyDialog(service) {
            const param = {
                service: (this.showServiceHappyDialogData =
                    service.realization?.signature),
                showDialog: true,
            }

            this.$emit('changeTitle', param)
        },
    },
}
</script>
<style lang="scss" scoped>
.orderDate {
    display: flex;
    justify-content: flex-start;
    word-spacing: 9999px;
}
.communication {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
}
.orderingParty {
    display: flex;
    flex-direction: column;

    & span:nth-child(2) {
        color: grey !important;
    }
    & span:nth-child(3) {
        color: grey !important;
    }
}
.deviceAndSn {
    display: flex;
    flex-direction: column;

    & .name {
        color: rgb(33, 99, 158);
        font-weight: bold;
    }
    & .se {
        color: grey;
    }
}
.interventionDate {
    display: flex;
    flex-direction: column;
}
.technician {
    display: flex;
    flex-direction: column;
}

.secondary-color {
    color: #a8a8a8;
}

.checkServiceHappy {
    cursor: pointer;
}
</style>
