<template>
    <v-container id="InnerTableContainer" class="pt-10">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="$t('service:service-orders')"
                    avatar-data="getordersRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                    :items="getServicesTable.items"
                    :headers="headers"
                    :length="getServicesTable.pages"
                    :page="getServicesTable.page"
                    :loading="getServicesTable.loading"
                    :default-fields="getServicesSearchFields.searchFields"
                    :is-sort-disabled="true"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                >
                    <Columns
                        @changeTitle="showServiceHappyDialog"
                        slot="columns"
                        :items="getServicesTable.items"
                    />
                </Table>
                <template>
                    <div class="text-center">
                        <v-dialog v-model="happyDialog.showDialog" width="500">
                            <v-card>
                                <div class="text-center pt-7 pb-2">
                                    <span class="headerColor">{{ $t('service:signatureConfirm') }}</span>
                                </div>
                                <div class="signature">
                                    <img
                                        class="signature--image"
                                        v-if="
                                            $get(
                                                happyDialog,
                                                'service.signature.data',
                                                ''
                                            )
                                        "
                                        :src="
                                            happyDialog.service.signature.data
                                        "
                                        alt=""
                                    />
                                    <div
                                        v-else
                                        class="signature--image-empty"
                                    ></div>
                                </div>
                                <div class="signature--date mb-8">
                                    <span>
                                        {{
                                            $t(
                                                'service:signatureConfirmedClientText'
                                            )
                                        }}</span
                                    >
                                    <span>{{
                                        $get(happyDialog, 'service.date', '')
                                    }}</span>
                                </div>
                                <v-divider></v-divider>
                                <div class="text-center pt-5 pb-5">
                                    <v-btn
                                        v-if="checkServiceHappy() === 'HAPPY'"
                                        class="buttons--add"
                                        @click="happyDialog.showDialog = false"
                                    >
                                        {{ $t('service:correct') }}
                                    </v-btn>
                                    <v-btn
                                        v-if="checkServiceHappy() === 'SAD'"
                                        color="error"
                                        @click="happyDialog.showDialog = false"
                                    >
                                        {{ $t('service:incorrect') }}
                                    </v-btn>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>
                <Modal
                    :title="$t('service:serviceRequest')"
                    :open="open"
                    :height="'580'"
                    @close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        slot="buttons"
                        :action="'create'"
                        @closeModal="open = false"
                    />
                </Modal>
                <div
                    class="d-flex justify-end flex-row-reverse buttons--details mt-8"
                >
                    <ButtonsService @add-service="addService()" />
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import store from './../../../store/index'
import Columns from './../../../components/Registries/ServiceOrders/Table/Columns'
import ButtonsService from '../../../components/Registries/ServiceOrders/Buttons/ButtonsService'
import AEContent from '../../../components/Registries/ServiceOrders/NewServiceModal/Content.vue'
import Buttons from '../../../components/Registries/ServiceOrders/NewServiceModal/Buttons'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    components: {
        Columns,
        ButtonsService,
        AEContent,
        Buttons,
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/service/:id') {
            store.commit('clearServiceTable')
            store.dispatch('fetchServiceTable').then(() => next())
        }
        next()
    },
    data() {
        return {
            happyDialog: {},
            open: false,
            headers: [
                {
                    text: this.$t('service:orderDate'),
                    value: 'createdAt',
                    width: '10%',
                },
                {
                    text: this.$t('service:orderingParty'),
                    value: 'createdByEntelliUsers.name join createdByEntelliUsers.lastName createdByEntelliUsers.phone createdByEntelliUsers.mail createdByHelpDesk.name join createdByHelpDesk.lastName createdByHelpDesk.phone createdByHelpDesk.mail  ',
                    width: '15%',
                    showSearch: true,
                },
                {
                    text: this.$t('service:deviceAndSn'),
                    value: 'device.sn join device.model.model join device.model.manufacturer',
                    width: '10%',
                    showSearch: true,
                },
                // clientDescription
                {
                    text: this.$t('service:customerDescription'),
                    value: 'clientDescription',
                    width: '35%',
                    showSearch: true,
                },
                {
                    text: this.$t('service:interventionDate'),
                    value: 'plannedServiceDate',
                    width: '10%',
                },
                {
                    text: this.$t('service:technician'),
                    value: 'technician.name join technician.lastname',
                    width: '8%',
                    showSearch: true,
                },
                // {
                //     text: this.$t('service:communication'),
                //     value: 'communication',
                //     width: '16%',
                // },
                {
                    text: '',
                    value: 'relization',
                    width: '50px',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'attachment',
                    width: '50px',
             
                },
                {
                    text: this.$t('service:status'),
                    value: 'status.name',
                    width: '150px',
                    showSearch: true,
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getServicesTable',
            'getServicesSearchFields',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions(['fetchServiceTable']),
        ...mapMutations([
            'setServiceTable',
            'setServiceSearch',
            'setServiceSearch',
            'clearServiceModal',
            'clearAlertsErrors',
            'clearServiceTable',
        ]),
        setSearchFields(searchFields) {
            this.setServiceSearch(searchFields)
        },
        setSearch(search) {
            this.setServiceTable({ search, page: 1 })
            this.fetchServiceTable()
        },
        setPage(page) {
            this.setServiceTable({ page })
            this.fetchServiceTable()
        },
        setSort(sort) {
            this.setServiceTable({ sort })
            this.fetchServiceTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearServiceTable()
            this.fetchServiceTable()
        },
        async addService() {
            // this.fetchRegistriesTable({
            //     select: 'icon url',
            //     where: { url: 'devices' },
            // })
            this.clearServiceModal()
            this.open = true
        },
        closeModal() {
            this.clearAlertsErrors()
            this.open = false
        },
        showServiceHappyDialog($event) {
            const value = $event
            this.happyDialog = value
        },
        checkServiceHappy() {
            if (this.happyDialog?.service?.confirmService == true) {
                return 'HAPPY'
            }
            if (this.happyDialog?.service?.confirmService == false) {
                return 'SAD'
            } else {
                return ''
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.signature {
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 0 50px 30px 50px;

    img {
        width: 70%;
        min-height: 200px;
    }

    .headerColor {
        color: red !important;
    }

    &--image-empty {
        min-height: 200px;
    }

    &--date {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 14px;
    }
}
</style>
