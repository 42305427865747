<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="12" class="pa-0">
                <v-stepper v-model="getServiceStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider />
                            <v-stepper-step
                                :rules="[
                                    () => getServiceError.stepOne.validation,
                                ]"
                                editable
                                step="1"
                            >
                                {{ $t('service:deviceInformation') }}
                            </v-stepper-step>
                            <v-divider />
                            <v-stepper-step
                                :rules="[
                                    () => getServiceError.stepTwo.validation,
                                ]"
                                editable
                                step="2"
                            >
                                {{ $t('service:rangeOfServices') }}
                            </v-stepper-step>
                            <v-divider />
                            <v-stepper-step
                                :rules="[
                                    () => getServiceError.stepThree.validation,
                                ]"
                                editable
                                step="3"
                            >
                                {{ $t('service:addAttachment') }}
                            </v-stepper-step>
                            <v-divider />
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <!-- MODAL CONTACT -->
                                <Modal
                                    :title="$t('global:addition')"
                                    :open="modalContact.open"
                                    @close="closeModalContact"
                                >
                                    <template #buttons>
                                        <v-btn
                                            :disabled="disabled"
                                            class="buttons buttons--add"
                                            @click="addNewContact()"
                                            @closeModal="closeModalContact"
                                        >
                                            {{ $t('global:save') }}
                                        </v-btn>
                                    </template>
                                </Modal>
                                <!-- MODAL BRANCHES -->
                                <Modal
                                    :title="$t('global:addition')"
                                    :open="modalBranches.open"
                                    @close="closeModalBranches"
                                >
                                    <AEContentBranches slot="AEContent" />
                                    <template #buttons>
                                        <v-btn
                                            :disabled="disabled"
                                            class="buttons buttons--add"
                                            @click="addNewBranches()"
                                        >
                                            {{ $t('global:save') }}
                                        </v-btn>
                                    </template>
                                </Modal>
                                <!-- MODAL REPRESANTIVE -->
                                <Modal
                                    :title="$t('global:addition')"
                                    :open="modalRepresentative.open"
                                    @close="closeModalRepresentative"
                                >
                                    <AEContentRepresentative slot="AEContent" />

                                    <template #buttons>
                                        <v-btn
                                            :disabled="disabled"
                                            class="buttons buttons--add"
                                            @click="addNewRepresentative()"
                                        >
                                            {{ $t('global:save') }}
                                        </v-btn>
                                    </template>
                                </Modal>

                                <v-row>
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col cols="4" />
                                            <v-col
                                                class="setup-lineHeight"
                                                cols="4"
                                            >
                                                <span class="info-Text"
                                                    >{{
                                                        $t(
                                                            'service:searchInfoPlaceholder'
                                                        ).substring(0, 27)
                                                    }}
                                                    <b>{{
                                                        $t(
                                                            'service:searchInfoPlaceholder'
                                                        ).substring(27, 40)
                                                    }}</b
                                                    ><br />{{
                                                        $t(
                                                            'service:searchInfoPlaceholder'
                                                        ).substring(40, 74)
                                                    }}</span
                                                >
                                                <v-combobox
                                                    v-model="
                                                        getServiceModal.device
                                                    "
                                                    class="deviceCombobox"
                                                    dense
                                                    :error-messages="
                                                        getServiceError.stepOne
                                                            .fields.model
                                                    "
                                                    :hint="`${$t(
                                                        'global:startWriting'
                                                    )}`"
                                                    :label="`${$t(
                                                        'service:serialNumber'
                                                    )}`"
                                                    return-object
                                                    item-text="sn"
                                                    item-value="_id"
                                                    :items="devices"
                                                    @keyup="
                                                        searchDevice(
                                                            $event.target.value
                                                        )
                                                    "
                                                    @input="
                                                        getLastIntervention()
                                                    "
                                                    @change="
                                                        ifClientOperationLocked
                                                    "
                                                >
                                                    <template
                                                        slot="selection"
                                                        slot-scope="data"
                                                    >
                                                        {{ data.item.sn }}
                                                    </template>
                                                    <template
                                                        slot="item"
                                                        slot-scope="data"
                                                    >
                                                        <span
                                                            :style="
                                                                data.item.status
                                                                    .name !==
                                                                'Aktualny'
                                                                    ? 'text-decoration: line-through'
                                                                    : ''
                                                            "
                                                            >{{
                                                                data.item.sn
                                                            }}</span
                                                        >
                                                        <v-tooltip right>
                                                            <template
                                                                #activator="{
                                                                    on,
                                                                    attrs,
                                                                }"
                                                            >
                                                                <v-icon
                                                                    v-if="
                                                                        data
                                                                            .item
                                                                            .status
                                                                            .name !==
                                                                        'Aktualny'
                                                                    "
                                                                    v-bind="
                                                                        attrs
                                                                    "
                                                                    v-on="on"
                                                                >
                                                                    mdi-alert-octagon-outline
                                                                </v-icon>
                                                            </template>
                                                            <span>{{
                                                                `${
                                                                    $t(
                                                                        'service:disabledDeviceInfo'
                                                                    ) + 'wqe'
                                                                } ${
                                                                    devicesMenager.name
                                                                } ${
                                                                    devicesMenager.lastname
                                                                }`
                                                            }}</span>
                                                        </v-tooltip>
                                                    </template>
                                                    <!--                                                    <template #prepend>-->
                                                    <!--                                                        <v-icon-->
                                                    <!--                                                            color="secondary"-->
                                                    <!--                                                            size="18"-->
                                                    <!--                                                        >-->
                                                    <!--                                                            {{-->
                                                    <!--                                                                $get(-->
                                                    <!--                                                                    getRegistriesTable,-->
                                                    <!--                                                                    'items[0].icon',-->
                                                    <!--                                                                    ''-->
                                                    <!--                                                                )-->
                                                    <!--                                                            }}-->
                                                    <!--                                                        </v-icon>-->
                                                    <!--                                                    </template>-->
                                                </v-combobox>
                                            </v-col>
                                            <v-col cols="4">
                                                <div
                                                    class="deviceCombobox-info"
                                                >
                                                    <v-tooltip right>
                                                        <template
                                                            #activator="{
                                                                on,
                                                                attrs,
                                                            }"
                                                        >
                                                            <v-icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                class="informationIcon"
                                                            >
                                                                mdi-information
                                                            </v-icon>
                                                        </template>
                                                        <span>{{
                                                            $t(
                                                                'service:serialNumberUserInfo'
                                                            )
                                                        }}</span>
                                                    </v-tooltip>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                md="12"
                                                class="margin--deviceInformation"
                                            >
                                                <Separator
                                                    :text="
                                                        $t(
                                                            'service:deviceInformation'
                                                        )
                                                    "
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                lg="12"
                                                xl="12"
                                                md="12"
                                                sm="12"
                                                class="d-flex flex-column"
                                            >
                                                <SummaryLine
                                                    :padding-bottom="3"
                                                    class="summaryLine--font"
                                                    :firtst-line="
                                                        $t(
                                                            'service:manufacturer-model'
                                                        )
                                                    "
                                                    :text="
                                                        $get(
                                                            getServiceModal,
                                                            'device.manufacturer',
                                                            ''
                                                        ) +
                                                        ' ' +
                                                        $get(
                                                            getServiceModal,
                                                            'device.model.model',
                                                            ''
                                                        )
                                                    "
                                                    :readonly="true"
                                                    :disabled="true"
                                                />

                                                <SummaryLine
                                                    :padding-bottom="15"
                                                    class="summaryLine--font"
                                                    :width-right-site="400"
                                                    :firtst-line="
                                                        $t('service:deviceType')
                                                    "
                                                    :text="
                                                        $get(
                                                            getServiceModal,
                                                            'device.model.deviceType',
                                                            ''
                                                        )
                                                    "
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    :padding-bottom="3"
                                                    class="summaryLine--font"
                                                    :firtst-line="
                                                        $t('service:client')
                                                    "
                                                    :text="
                                                        $get(
                                                            getServiceModal,
                                                            'device.client.name',
                                                            ''
                                                        )
                                                    "
                                                    :width-right-site="250"
                                                    :readonly="true"
                                                    :disabled="true"
                                                />

                                                <SummaryLine
                                                    class="summaryLine--font"
                                                    :firtst-line="
                                                        $t('service:address')
                                                    "
                                                    :width-right-site="350"
                                                    :text="address"
                                                    :readonly="true"
                                                    :disabled="true"
                                                />

                                                <SummaryLine
                                                    :padding-bottom="3"
                                                    :width-right-site="550"
                                                    class="summaryLine--font"
                                                    :firtst-line="
                                                        $t(
                                                            'service:lastInterventionDate'
                                                        )
                                                    "
                                                    :text="
                                                        $get(
                                                            lastIntervention,
                                                            'realization.endDate',
                                                            ''
                                                        )
                                                            ? `${$moment(
                                                                  lastIntervention
                                                                      .realization
                                                                      .endDate
                                                              ).format(
                                                                  'DD-MM-YYYY'
                                                              )} ${
                                                                  $get(
                                                                      lastIntervention,
                                                                      'realization.endTime',
                                                                      ''
                                                                  ) || ''
                                                              }`
                                                            : ''
                                                    "
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    :padding-bottom="3"
                                                    class="summaryLine--font"
                                                    :firtst-line="
                                                        $t('service:technician')
                                                    "
                                                    :text="
                                                        $get(
                                                            lastIntervention,
                                                            'technician.name',
                                                            ''
                                                        ) +
                                                        ' ' +
                                                        $get(
                                                            lastIntervention,
                                                            'technician.lastname',
                                                            ''
                                                        )
                                                    "
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-row>
                                    <v-col cols="12" md="12" class="pb-0">
                                        <Separator
                                            :text="
                                                $t('service:serviceDescription')
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12" class="py-0 pt-3">
                                        <v-textarea
                                            v-model="
                                                getServiceModal.clientDescription
                                            "
                                            :error-messages="
                                                getServiceError.stepTwo.fields
                                                    .clientDescription
                                            "
                                            auto-grow
                                            rows="1"
                                            no-resize
                                            :label="`${$t(
                                                'service:customerDescription'
                                            )}*`"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row class="pt-2">
                                    <v-col cols="3" md="6" class="py-0">
                                        <v-select
                                            ref="presonSelect"
                                            v-model="getProfileDetails"
                                            :label="`${$t(
                                                'service:personContracts'
                                            )}`"
                                            :items="[getProfileDetails]"
                                            return-object
                                            item-value="_id"
                                            readonly
                                            append-icon=""
                                        >
                                            <template #selection="data">
                                                <div class="mr-1">
                                                    {{
                                                        ` ${data.item.name} ${data.item.lastName}`
                                                    }}
                                                </div>
                                                <span
                                                    v-if="data.item.email"
                                                    class="mr-1"
                                                >
                                                    {{
                                                        ` • ${data.item.email} `
                                                    }}
                                                </span>
                                                <span
                                                    v-if="data.item.phoneNumber"
                                                >
                                                    {{
                                                        ` • ${data.item.phoneNumber}`
                                                    }}
                                                </span>
                                            </template>
                                            <template #item="data">
                                                <div class="mr-1">
                                                    {{
                                                        ` ${data.item.name} ${data.item.lastName}`
                                                    }}
                                                </div>
                                                <span
                                                    v-if="data.item.email"
                                                    class="mr-1"
                                                >
                                                    {{
                                                        ` • ${data.item.email} `
                                                    }}
                                                </span>
                                                <span
                                                    v-if="data.item.phoneNumber"
                                                >
                                                    {{
                                                        ` • ${data.item.phoneNumber}`
                                                    }}
                                                </span>
                                            </template>
                                            <!-- <template #append>
    <v-btn
        v-if="
            getServiceModal.contractor
        "
        icon
        small
        @mouseup="removeSelected"
    >
        <v-icon size="15">
            mdi-close
        </v-icon>
    </v-btn>
    <v-btn icon small>
        <v-icon> $dropdown </v-icon>
    </v-btn>
</template> -->
                                        </v-select>
                                    </v-col>
                                    <v-col cols="" class="py-0 pb-8">
                                        <v-menu
                                            v-model="expectationsDate"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template
                                                #activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getServiceModal.expectedServiceDate
                                                    "
                                                    clearable
                                                    :label="`${$t(
                                                        'service:expectedServiceDate'
                                                    )}`"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                />
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    getServiceModal.expectedServiceDate
                                                "
                                                first-day-of-week="1"
                                                :min="
                                                    $moment().format(
                                                        'YYYY-MM-DD'
                                                    )
                                                "
                                                @input="
                                                    expectationsDate = false
                                                "
                                            />
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="2" class="py-0">
                                        <v-menu
                                            ref="menu2"
                                            v-model="expectedTime"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template
                                                #activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getServiceModal.expectedServiceTime
                                                    "
                                                    clearable
                                                    :label="`${$t(
                                                        'service:time'
                                                    )}`"
                                                    prepend-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                />
                                            </template>
                                            <v-time-picker
                                                v-if="expectedTime"
                                                v-model="
                                                    getServiceModal.expectedServiceTime
                                                "
                                                format="24hr"
                                                full-width
                                                @click:minute="
                                                    $refs.menu2.save(
                                                        getServiceModal.expectedServiceTime
                                                    )
                                                "
                                            />
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6"></v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="priority"
                                            :label="`${$t('service:priority')}`"
                                            prepend-icon="mdi-clock-fast"
                                            readonly
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12" class="mt-5">
                                        <Separator
                                            :text="
                                                $t(
                                                    'service:implementationIntervention'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row class="pb-5">
                                    <v-col cols="4" class="pt-0">
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .survey
                                            "
                                            class="checkbox--implementation mt-0"
                                            :label="`${$t('service:survey')}`"
                                            color="secondary"
                                        />
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .repair
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t('service:repair')}`"
                                            color="secondary"
                                        />
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .installation
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t(
                                                'service:implementation'
                                            )}`"
                                            color="secondary"
                                        />
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .diagnostics
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t(
                                                'service:diagnostics'
                                            )}`"
                                            color="secondary"
                                        />
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .itService
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t(
                                                'service:ItService'
                                            )}`"
                                            color="secondary"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="4"
                                        class="pt-0 checkboxes--layout-end"
                                    >
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .remoteService
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t('service:remote')}`"
                                            color="secondary"
                                        />
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .reception
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t(
                                                'service:reception'
                                            )}`"
                                            color="secondary"
                                        />
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .supply
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t('service:supply')}`"
                                            color="secondary"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="4"
                                        class="checkboxes--layout-end--margin"
                                    >
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .complaint
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t(
                                                'service:complaint'
                                            )}`"
                                            color="secondary"
                                        />
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .urgent
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t('service:urgent')}`"
                                            color="red"
                                        />
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="3">
                                <form enctype="multipart/form-data">
                                    <label for="files">
                                        <v-icon size="45">mdi-file</v-icon>
                                        <div
                                            class="fileLine"
                                            :style="`--progress:${progress}%`"
                                        ></div>
                                        <span>{{
                                            $t('attachments:dragAndDropText')
                                        }}</span>
                                        <input
                                            type="file"
                                            id="files"
                                            name="file"
                                            multiple
                                            @change="addFile($event)"
                                        />
                                    </label>
                                    <div class="files">
                                        <div
                                            class="files__item"
                                            v-for="(
                                                file, index
                                            ) in getFilesModalFields"
                                            :key="index"
                                        >
                                            <v-btn
                                                icon
                                                class="files__remove"
                                                @click="
                                                    removeFileFromList(file)
                                                "
                                            >
                                                <v-icon color="red"
                                                    >mdi-close
                                                </v-icon>
                                            </v-btn>
                                            <v-icon large>mdi-file</v-icon>
                                            <p class="file__name truncate">
                                                {{ file.name }}
                                            </p>
                                            <p class="file__size">
                                                {{
                                                    bytesToMegaBytes(file.size)
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="counter">
                                        <p>
                                            {{ $t('attachments:filesLength') }}:
                                            {{ getFilesModalFields.length }}
                                        </p>
                                        <p>
                                            {{ $t('attachments:filesSize') }}:
                                            {{ totalFilesSize() }}
                                        </p>
                                    </div>
                                </form>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            expectationsDate: '',
            expectedTime: '',
            plannedDate: '',
            plannedTime: '',
            date: '',
            time: '',
            hideDelivery: true,
            options: [],
            modalContact: {
                open: false,
            },
            modalBranches: {
                open: false,
            },
            modalRepresentative: {
                open: false,
            },
            disabled: false,
            editable: true,
            devices: [],
            technicians: [],
            devicesMenager: {},
            lastIntervention: {},
            clientHasOperationLocked: '',
            progress: 0,
        }
    },

    computed: {
        ...mapGetters([
            'getProfileDetails',
            'getDeviceModal',
            'getServiceStep',
            'getServiceError',
            'getModelsInput',
            'getContactsInput',
            'getBranchesTable',
            'getRepresentativeInput',
            'getServiceStep',
            'getServiceError',
            'getServiceModal',
            'getServicesRegistry',
            // 'getRegistriesTable',
            'getOperationLockDetails',
            'getFilesModalFields',
        ]),

        branches: {
            get() {
                if (this.getDeviceModal.client)
                    return this.getDeviceModal.client.branches.sort((a, b) =>
                        a.name > b.name ? 1 : -1
                    )
                else return []
            },
        },
        representatives: {
            get() {
                if (this.getDeviceModal.client)
                    return this.getDeviceModal.client.representatives.sort(
                        (a, b) => (a.lastname > b.lastname ? 1 : -1)
                    )
                else return []
            },
        },
        prices: {
            get() {
                let prices =
                    this.$get(
                        this.getServiceModal,
                        'device.model.servicePrice'
                    ) || null

                if (prices !== null) {
                    let str = ''
                    str += ` ${Number(
                        this.$get(prices, 'survey.price', 0)
                    ).toFixed(2)} PLN / `
                    str += `${Number(
                        this.$get(prices, 'repair.price', 0)
                    ).toFixed(2)} PLN / `
                    str += `${Number(
                        this.$get(prices, 'installation.price', 0)
                    ).toFixed(2)} PLN / `
                    str += `${Number(
                        this.$get(prices, 'diagnostics.price', 0)
                    ).toFixed(2)} PLN / `
                    str += `${Number(
                        this.$get(prices, 'itService.price', 0)
                    ).toFixed(2)} PLN `
                    return str
                }
            },
        },
        address: {
            get() {
                let branches = this.$get(
                    this.getServiceModal,
                    'device.client.branches',
                    []
                )

                if (!this.getServiceModal.device) return ''
                let branch =
                    branches.find(
                        el => el._id === this.getServiceModal.device.branch
                    ) || this.getServiceModal.device.branch

                if (branch)
                    return `${branch.name} ${branch.address} \n ${
                        branch.postCode
                    } ${branch.city} ${
                        this.getServiceModal.device.floor
                            ? this.getServiceModal.device.floor
                            : ''
                    }`
                else return ''
            },
        },
        contractors: {
            get() {
                let representatives = this.$get(
                    this.getServiceModal,
                    'device.client.representatives',
                    []
                )
                // return representatives.map(r => `${r.name} ${r.lastname}`)
                return representatives
            },
        },
        priority: {
            get() {
                let priority = this.$get(
                    this.getServiceModal,
                    'device.priority.field',
                    ''
                )

                if (priority) {
                    return priority
                }
                return 'Brak informacji'
            },
        },
    },
    async mounted() {
        this.searchTechnicians()
        let menager = await new this.Rest('GET', 'registries/devices')
            .addParams({ url: true })
            .select('currentMenager')
            .populate(['currentMenager'])
            .send()
        this.devicesMenager = menager.data.currentMenager
        await this.getLastIntervention()
    },
    methods: {
        ...mapActions([
            'createContact',
            'createBranch',
            'createRepresentative',
            'fetchBranchesTable',
            'fetchContactsInputs',
            'fetchRepresentativesInput',
            'fetchOperationLockTable',
            'checkIsClientOperationLocked',
            'setFileOnFilesList',
            'removeFileFromFilesList',
        ]),
        ...mapMutations([
            'clearRepresentativesErrors',
            'clearRepresentsativeModal',
            'clearBranchErrors',
            'clearBranchModal',
        ]),
        calcProgress(current, completed) {
            let percent = Math.round((current * 100) / completed) || 0
            this.progress = percent > 100 ? 100 : percent
        },

        addFile(event) {
            // CALL ACTION TO ADD EVERY FILE
            for (let file of event.target.files) {
                this.setFileOnFilesList(file)
            }
          event.target.value = ''
        },
        removeFileFromList(file) {
            this.removeFileFromFilesList(file)
        },
        bytesToMegaBytes(a, b = 2) {
            if (0 === a) return '0 Bytes'
            const c = 0 > b ? 0 : b,
                d = Math.floor(Math.log(a) / Math.log(1024))
            return (
                parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
                ' ' +
                ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
            )
        },
        totalFilesSize() {
            // TODO FIX
            let total = this.getFilesModalFields.reduce(
                (accumulator, item) => accumulator + item.size,
                0
            )
            return this.bytesToMegaBytes(total)
        },

        removeSelected() {
            this.$refs['presonSelect'].reset()
            this.getServiceModal.contractor = null
        },
        async ifClientOperationLocked() {
            const clientId = this.$get(
                this.getServiceModal,
                'device.client._id',
                ''
            )

            if (clientId) {
                const payload = {
                    id: clientId,
                }
                const checkClientOperationLocked =
                    await this.checkIsClientOperationLocked(payload)

                if (checkClientOperationLocked?.data) {
                    this.getServiceError.stepOne.fields.model =
                        'Klient na liście zablokowanych'
                    this.getServiceModal.isClientOperationLocked = true

                    this.$forceUpdate()
                } else {
                    this.getServiceError.stepOne.fields.model = ''
                    this.getServiceModal.isClientOperationLocked = false
                    this.$forceUpdate()
                }
            }
        },
        async searchDevice(search) {
            if (search.length > 3) {
                let response = await new this.Rest('GET', 'devicesBySN')
                    .select(
                        'status manufacturer model deviceType priority servicePackage serviceBase floor client._id client.representatives client.name client.branches sn deliverer branch'
                    )
                    .populate([
                        {
                            path: 'model',
                        },
                        {
                            path: 'client',
                        },
                    ])
                    .addParams({ SN: search })
                    .send()

                // if (!this.devicesMenager.name) {
                //     let menager = await new this.Rest(
                //         'GET',
                //         'registries/devices'
                //     )
                //         .addParams({ url: true })
                //         .select('currentMenager')
                //         .populate(['currentMenager'])
                //         .send()
                //     this.devicesMenager = menager.data.currentMenager
                // }
                this.devices = response.data.items
            } else this.devices = []
        },

        async getLastIntervention() {
            if (!this.getServiceModal.device) return
            let date
            if (
                this.getServiceModal.realization &&
                this.getServiceModal.realization.startDate
            )
                date = new Date(this.getServiceModal.realization.startDate)
            if (!date) date = new Date()
            if (this.getServiceModal.device._id) {
                try {
                    let lastIntervention = await new this.Rest(
                        'GET',
                        `service/${this.getServiceModal.device._id}/previous`
                    )
                        .select('technician, realization')
                        .populate({
                            path: 'technician',
                        })
                        .addParams({ date: date })
                        .send()
                    if (lastIntervention.data)
                        this.lastIntervention = lastIntervention.data
                } catch (e) {
                    console.error(e)
                }
            }
        },

        async searchTechnicians() {
            if (this.technicians.length > 0) return
            let response = await new this.Rest('GET', `/users`)
                .where({
                    specialRoleName: 'technic',
                })
                .select('_id name lastname')
                .send()

            this.technicians = response.data.items
            // this.technicians = response.data.items
        },
        clearItem() {
            this.getContactsInput.items = []
        },
        closeModalContact() {
            this.modalContact.open = false
        },
        closeModalBranches() {
            this.modalBranches.open = false
            this.clearBranchErrors()
            this.clearBranchModal()
        },
        closeModalRepresentative() {
            this.modalRepresentative.open = false
            this.clearRepresentativesErrors()
            this.clearRepresentsativeModal()
        },
        async addNewContact() {
            let success = await this.createContact()
            if (success) {
                let contactSuccess = await this.fetchContactsInputs(
                    '-createdAt'
                )
                if (contactSuccess) {
                    this.getDeviceModal.branch =
                        this.getContactsInput.items[0].branches[0]
                    this.getDeviceModal.client = this.getContactsInput.items[0]
                    this.closeModalContact()
                }
            }
        },
        async addNewRepresentative() {
            if (this.getServiceModal.device.client) {
                let success = await this.createRepresentative({
                    id: this.getServiceModal.device.client._id,
                })
                if (success) {
                    this.modalRepresentative.open = false
                    this.clearRepresentativesErrors()
                    this.clearRepresentsativeModal()
                    await this.fetchRepresentativesInput({
                        id: this.getServiceModal.device.client._id,
                        limit: 300,
                        sort: '-createdAt',
                    })

                    this.getServiceModal.device.client.representatives =
                        this.getRepresentativeInput.items
                    this.getServiceModal.representatives =
                        this.getRepresentativeInput.items[0]
                }
            }
        },
        async addNewBranches() {
            if (this.getDeviceModal.client) {
                let success = await this.createBranch({
                    id: this.getDeviceModal.client._id,
                })
                if (success) {
                    this.modalBranches.open = false
                    this.clearBranchErrors()
                    this.clearBranchModal()
                    await void this.fetchBranchesTable(
                        this.getDeviceModal.client._id
                    )
                    this.getDeviceModal.client.branches =
                        this.getBranchesTable.items
                    this.getDeviceModal.branch = this.getBranchesTable.items[0]
                }
            }
        },
    },
}
</script>
<style lang="sass" scoped>
.font--implementation
  font-size: 14px
  color: grey

.summaryLine--font
  font-size: 13px !important

.checkbox--implementation
  height: 20px
  margin-top: 10px
  margin-left: 10px

.deviceCombobox
  margin-top: 30px
  margin-bottom: 60px

.split-content
  margin-top: 100px

.checkboxes--layout-center
  display: flex
  flex-direction: column
  justify-content: center

.checkboxes--layout-end
  display: flex
  flex-direction: column
  justify-content: flex-end

.checkboxes--layout-end--margin
  display: flex
  flex-direction: column
  justify-content: flex-end
  margin-bottom: 1.95rem

.deviceCombobox-info
  display: flex
  margin-top: 70px
  margin-left: 0px
  font-size: clamp(0.55rem, 1vw + 0.5rem, 0.65rem)
  cursor: pointer

.info-Text
  padding-left: 4px
  font-size: clamp(0.65rem, 1vw + 0.5rem, 0.75rem)

.setup-lineHeight
  line-height: 19px !important

.informationIcon:hover
  color: green

label
  width: 100%
  height: 180px
  display: flex
  // justify-content: center
  align-items: center
  flex-direction: column
  position: relative
  background: #f3f3f3
  color: #445D75
  padding: 15px 30px

  span
    font-size: 22px
    font-weight: bold

  .fileLine
    --progress: 0%
    position: relative
    background: white
    width: 100%
    height: 5px
    margin-top: 10px
    margin-bottom: 20px

    &::before
      content: ''
      position: absolute
      width: var(--progress)
      height: 100%
      background-color: #19aa8d

input[type=file]
  height: 100%
  width: 100%
  position: absolute
  opacity: 0
  cursor: pointer

.files
  margin-top: 10px
  width: 744px
  max-height: 120px
  min-height: 120px
  overflow-y: auto
  display: flex
  flex-wrap: wrap

  &__item
    height: 100px
    width: calc(20% - 10px)
    margin: 5px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    position: relative

    p
      margin: 0
      max-width: 100%

  &__remove
    position: absolute
    top: 0
    right: 20%
    z-index: 2

.file__size
  font-size: 12px
  color: #ababab

.counter
  display: flex
  flex-direction: column
  margin-top: 20px
  margin-bottom: 70px

  p
    margin-bottom: 0
    font-size: 12px
    color: #ababab
</style>
